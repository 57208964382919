<template>
    <b-card no-body class="m-2">
        <b-card-header class="pb-50">
            <h5 class="text-capitalize">
                {{ $tc('actions.filter', 1) }}
            </h5>
        </b-card-header>
        <b-card-body>
            <b-row class="align-items-end">
                <b-col
                    cols="12"
                    md="3"
                    lg="3"
                    class="mb-md-0 mb-2"
                >
                    <label>
                        {{ $tc('luxriot.master_account', 1) }}
                    </label>
                    <v-select
                        label="title"
                        :placeholder="$tc('luxriot.placeholderMasterAccount', 2)"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="masterAccounts"
                        :reduce="({ id }) => id"
                        @input="(val) => $emit('master-account-selected', val)"
                    />
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>
  
<script>
import {
    BCard, 
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from '@/libs/axios'
import { onMounted, ref } from '@vue/composition-api'
  
export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BButton,
        vSelect
    },
    setup(_, { emit }) {
        onMounted(async () => {
            let requestMasterAccounts = { isList: true }

            let { data } = await axios.get('/v1/services/luxriot/master-accounts', { params: requestMasterAccounts })
            
            let { data: dataApi, success} = data
            
            if (!success) {
                masterAccounts.value = []
            }

            masterAccounts.value = dataApi
        })

        //Data
        const masterAccounts = ref([])

        return { masterAccounts }
    }    
}
</script>