<template>
    <div>
        <sidenav ref="sidenav" :is-sidebar-active.sync="isSidebarActive" :item-edit.sync="itemEdit" :is-add.sync="isAdd"
          :units="units" :cameras-types="cameraTypeOptions" @refetch-data="refetchData"
        />

        <Breadcrumb
          :nameNav="nameNav"
          :itemFilter="itemFilter"
          :searchQuery="searchQuery"
          @itemFilterSearch="addItemFilterSearch"
        />

        <filters @master-account-selected="masterAccounySelected" />
        <b-card class="m-2" no-body>
            <div class="p-2">
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label class="text-capitalize">{{ $t("table.show") }} </label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
                        <label class="text-capitalize">{{ $t("table.entries") }} </label>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1"
                                :placeholder="$t('table.search')" />
                        </div>
                    </b-col>
                </b-row>
            </div>

            <b-table ref="refListTable" class="position-relative" :items="fetchList" responsive :fields="tableColumns"
                primary-key="id" :sort-by.sync="sortBy" show-empty :empty-text="$t('no_record_found')"
                :sort-desc.sync="isSortDirDesc">
                <template #head()="data">
                    <span> {{ $tc(data.label, 1) }}</span>
                </template>

                <!-- Column: Preview -->
                <template #cell(title)="data">
                    <div style="position: relative;
                                    width: 250px;">
                        <img :src="`${snapshotUrl}${data.item.camera.id}?access_token=${token}`"
                            style="width: 250px; border-radius: 16px;" />
                        <div style="z-index: 1;
                        position: absolute;
                        width: -webkit-fill-available;
                        color: white;
                        background: rgba(0,0,0,.5);
                        padding: 5px;
                        top: 0;
                        right: 0;
                        left: 0;
                        border-top-left-radius: 16px;
                        border-top-right-radius: 16px;
                        ">
                            <b-media vertical-align="center">
                                <template #aside>
                                    <div :style="`background: ${data.item.camera.online ? 'green' : 'red'};
                                        width: 1.5rem;
                                        height: 1.5rem;
                                        border-radius: 1rem;
                                        text-align: center;`">
                                    </div>
                                </template>
                                <small style="font-weight: bolder; font-size: 14px;">{{ data.item.title }}</small>
                            </b-media>
                        </div>
                    </div>
                </template>

                <template #head(luxriot_master_account)="data">
                    <span> {{ $tc(data.label, 2) }}</span>
                </template>

                <template #head(type)="data">
                    <span> {{ $tc(data.label, 2) }}</span>
                </template>

                <template #head(center)="data">
                    <span> {{ $tc(data.label, 2) }}</span>
                </template>

                <template #head(sector)="data">
                    <span> {{ $tc(data.label, 2) }}</span>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-button :id="`actions-${data.item.id}`" tabindex="0" variant="white" class="px-1 btn-y">
                        <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                    </b-button>
                    <b-popover :target="`actions-${data.item.id}`" triggers="focus" placement="bottom"
                        custom-class="popover-p-0" variant="white">
                        <b-list-group class="m-0">

                            <b-list-group-item class="border-0" button
                                @click="updateStatusCamera(data.item.camera.id)">
                                <feather-icon icon="EditIcon" />
                                <span class="align-middle ml-50 text-capitalize">
                                    {{ $t("actions.update") }}
                                </span>
                            </b-list-group-item>

                            <b-list-group-item v-if="$can('update', 'LuxriotCameras')" class="border-0" button
                                @click="openEdit(data.item)">
                                <feather-icon icon="EditIcon" />
                                <span class="align-middle ml-50 text-capitalize">
                                    {{ $t("actions.edit") }}
                                </span>
                            </b-list-group-item>
                        </b-list-group>
                    </b-popover>
                </template>
            </b-table>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted text-capitalize">
                            {{ $t('table.showing', { from: dataMeta.from, to: dataMeta.to, of: dataMeta.of }) }}
                        </span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalList" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    // BBadge,
    BDropdown,
    BDropdownItem,
    BPagination
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, computed } from '@vue/composition-api'
import useList from './useList'
import store from "@/store"
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import Sidenav from './Sidenav.vue'
import Filters from './Filters.vue'
import { useToast } from "vue-toastification/composition"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import Breadcrumb from '@/components/Breadcrumb.vue'

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BPagination,
        Sidenav,
        Filters,
        vSelect,
        Breadcrumb
    },

    setup(_, context) {
        const isSidebarActive = ref(false)
        const itemEdit = ref({})
        const isAdd = ref(false)
        const sidenav = ref(null)

        const snapshotUrl = `${process.env.VUE_APP_BASE_URL}/v1/services/snapshot/`

        const openEdit = (item) => {
            isSidebarActive.value = true
            itemEdit.value = JSON.parse(JSON.stringify(item))
            isAdd.value = false
            sidenav.value.getCenterSectors(item.id_center, false)
        }
        const { t } = useI18nUtils()
        const toast = useToast();
        const {
            fetchList,
            tableColumns,
            perPage,
            currentPage,
            totalList,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refListTable,
            refetchData,
            formatDateAssigned,
            people_id,
            dateFrom,
            dateTo,
            luxriotMasterAccountId,
            cameraTypeOptions,

            tenantOptions,
            tenantFilter,
            contentToast,
            units,
        } = useList()

        //Methods

        const updateStatusCamera = (id) => {
            store
                .dispatch("camera_proxy/updateStatus", id)
                .then((response) => {
                    if (response.success) {
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: response.message,
                                icon: "CheckIcon",
                                variant: "success",
                            },
                        });
                    } else {
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: response.message,
                                icon: "AlertTriangleIcon",
                                variant: "danger",
                            },
                        });
                    }
                    refetchData();
                })
                .catch((response) => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: response.response.data.message,
                            icon: "AlertTriangleIcon",
                            variant: "danger",
                        },
                    });
                });
        }

        const masterAccounySelected = (master_account_id) => {
            luxriotMasterAccountId.value = master_account_id
        }

        const openAdd = () => {
            isAdd.value = true
            isSidebarActive.value = true
        }

        const peopleSelected = (peopleId) => {
            people_id.value = peopleId
        }

        const setDateFrom = (date) => {
            dateFrom.value = date
        }

        const setDateTo = (date) => {
            dateTo.value = date
        }

        const itemFilter = ref();
        const addItemFilter = (data) => {
          itemFilter.value = data;
        };
        const addItemFilterSearch = (data) => {
          data.forEach((element) => {
            if (nameNav.value === element.text) {
              searchQuery.value = element.searchQuery;
            }
          });
        };
        const nameNav = ref("breadcrumbs.vass.luxriot.cameras");

        return {
            // Sidebar
            isSidebarActive,
            isAdd,
            itemEdit,
            openEdit,
            openAdd,

            nameNav,
            itemFilter,
            addItemFilter,
            addItemFilterSearch,

            fetchList,
            tableColumns,
            perPage,
            currentPage,
            totalList,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refListTable,
            refetchData,
            formatDateAssigned,
            units,

            tenantOptions,
            tenantFilter,

            peopleSelected,
            setDateFrom,
            setDateTo,
            sidenav,
            masterAccounySelected,
            cameraTypeOptions,

            snapshotUrl,
            token: localStorage.accessToken,
            updateStatusCamera
        }
    }
}
</script>